<template>
  <div v-if="order">

    <h4>Tipo de producto: {{ order.product_type.name }}</h4>
    <h6>Cliente: {{ order.client.business_name }}</h6>
    <hr>
    <!-- User Info: Input Fields -->
    <validation-observer ref="formReadSerial" v-if="order.order_status_id == 2">

      <b-form
        @submit.prevent="saveSerial">
        <b-row>

          <!-- Field: Username -->
          <b-col
            cols="12"
            md="4"
          >

            <b-form-group
                label="Serial *"
                label-for="serial"
              >
              <validation-provider
                  #default="{ errors }"
                  name="Serial"
                  rules="required"
                >

                <b-form-input
                  id="serial"
                  v-model="serial"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>

          </b-col>


          <b-col
            cols="12"
            md="4"
            v-if="order.product_type_id != 1"
          >

            <b-form-group
                label="SIM *"
                label-for="sim"
              >
              <validation-provider
                  #default="{ errors }"
                  name="SIM"
                  rules="required"
                >

                <b-form-input
                  id="sim"
                  v-model="sim"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>

          </b-col>
          
        </b-row>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          Guardar cambios
        </b-button>
        <b-button
          variant="outline-secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="$router.push({name: 'order-list'})"
        >
          Cancelar
        </b-button>
      
      </b-form>
    </validation-observer>

    <div v-else>

      <h6>Estado: {{ order.order_status.name }}</h6>
      <h6>Descripción: {{ order.order_status.description }}</h6>
      <h6>Cantidad de seriales: {{ order.quantity_products }}</h6>

      <b-button
        variant="outline-secondary"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="$router.push({name: 'order-list'})"
        class="mt-2"
      >
        Ver pedidos
      </b-button>

    </div>
    
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, 
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { required, } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {constants} from "@/helpers/constants"

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken'),
      serial: '',
      sim: '',
      order: null,
      required,
    }
  },
  props: {
    orderId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const countryOptions = [
      { label: 'Colombia', value: 1 },
    ]

    const serializedOptions = [
      { label: 'Texto', value: 0 },
      { label: 'JSON', value: 1 },
    ]

    return {
      countryOptions,
      serializedOptions,
    }
  },
  created() {
    this.getOrder()
  },
  methods: {
    getOrder() {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.get(this.host + '/api/front-web/get-order-enlistment/' + this.orderId, { headers }).then(response => {
        console.log('order: ', response)

        this.order = response.data
        
      }).catch( err => {
        console.log(err)
      })
    },
    saveSerial() {
      event.preventDefault()
      this.$refs.formReadSerial.validate().then(success => {
        if (success) {

          let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.accessToken
          }
          axios.post(this.host + '/api/front-web/read-product',{
            order_id: this.order.id,
            product_type_id: this.order.product_type_id,
            serial: this.serial,
            sim_serial: this.sim,
          }, { headers }).then(response => {
            console.log('read-product: ', response)

            if(response.data.status) {
              this.serial = ''
              this.sim = ''
              this.$toast({
                component: ToastificationContentVue,
                position: 'top-right',
                props: {
                  title: `OK`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              if(response.data.data.order_complete || response.data.data.finish_order) {
                this.getOrder()
              }
            } else {
              this.$toast({
                component: ToastificationContentVue,
                position: 'top-right',
                props: {
                  title: `Advertencia`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
            }
          }).catch( err => {
            console.log(err)
          })

        }
      })


    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
