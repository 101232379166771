<template>

  <b-card title="Alistamiento de pedido">

    
    <b-row>

      <b-col v-if="order" cols="12" class="mb-2">
        <b-card-text>Aca se visualizan todos los seriales que estan ya disponibles por estiba para asignar al pedido correspondiente</b-card-text>

        <small>Detalle del pedido</small>
        <h6>Tipo de producto: {{ order.product_type.name }}</h6>
        <h6>Cliente: {{ order.client.business_name }}</h6>
        <h6>Destino: {{ order.destination.name + ' - ' + order.destination.address }}</h6>
        <h6>Cantidad de seriales: {{ order.quantity_products }}</h6>
        
      </b-col>

      <b-col 
        v-for="(stowage, index) in stowages"
        :key="index"
        cols="12">

        <b-card
          no-body
          class="card-apply-job"
          bg-variant="light-secondary"
        >
          <b-card-header class="pb-1">
            <b-media no-body>
              <b-media-body>
                <h5 class="mb-0">
                  Estiba #{{ stowage.stowage.stowage_number }} | {{ stowage.product_qty }} Seriales
                  <b-badge
                    pill
                    :variant="stowage.stowage.have_sim ? 'info' : 'warning'"
                  >
                    {{ stowage.stowage.have_sim ? 'Con SIM' : 'Sin SIM' }}
                  </b-badge>
                </h5>
                <small class="text-muted">Creada: {{ formatDateVariant(stowage.stowage.created_at) }}</small>
                <b-card 
                  class="mt-1"
                  bg-variant="light-info">

                  <b-form-checkbox
                    v-model="stowageValue"
                    :value="stowage.stowage.id"
                    @change="selectAll(stowage.stowage.id)"
                  >
                    Seleccionar todas de la estiba #{{ stowage.stowage.stowage_number }}
                  </b-form-checkbox>

                </b-card>

              </b-media-body>
            </b-media>

            
            <b-badge
              pill
              variant="light-success"
            >
              {{ stowage.stowage.available == 1 ? 'Disponible' : 'No disponible' }}
            </b-badge>
          </b-card-header>
          <b-card-body>

            <b-row>
              <b-col 
                v-for="(box, index) in stowage.stowage.boxes"
                :key="index"
                cols="3">

                <b-card
                  class="card-apply-job"
                  :title="box.products_by_box + ' seriales'"
                  :bg-variant="box.available == 0 ? 'light-secondary' : ''"
                >

                  <b-form-checkbox
                    v-model="selectedBoxes"
                    :value="box.id"
                    :disabled="box.available == 0"
                    class="custom-control-success"
                  >
                    Caja #{{ box.box_number }}
                    <small class="text-danger">{{ box.available == 0 ? ' No disponible' : '' }}</small>
                  </b-form-checkbox>

                </b-card>

              </b-col>
            </b-row>

          </b-card-body>
        </b-card>

      </b-col>

      <!-- <b-col 
        v-for="(stowage, index) in stowages"
        :key="index"
        cols="3">

        <b-card
          no-body
          class="card-apply-job"
          bg-variant="light-secondary"
        >
          <b-card-header class="pb-1">
            <b-media no-body>
              <b-media-body>
                <h5 class="mb-0">
                  Estiba #{{ stowage.stowage.stowage_number }}
                </h5>
                <small class="text-muted">Creada: {{ formatDateVariant(stowage.stowage.created_at) }}</small>
              </b-media-body>
            </b-media>
            <b-badge
              pill
              variant="light-success"
            >
              {{ stowage.stowage.available == 1 ? 'Disponible' : 'No disponible' }}
            </b-badge>
          </b-card-header>
          <b-card-body>

            <div class="apply-job-package bg-light-info rounded">
              <div>
                <h2 class="d-inline mr-25">
                  {{ stowage.product_qty }}
                </h2>
                <sub class="text-body"><small> seriales</small></sub>
              </div>
            </div>
            <b-button
              block
              variant="info"
              class="mt-2"
              @click="$router.push({name: 'stowage-detail', params: { stowage_id: stowage.stowage.id } })"
            >
              Ver estiba
            </b-button>

          </b-card-body>
        </b-card>

      </b-col> -->

      <b-col v-if="order && order.order_status_id != 5" cols="6" class="mb-2">

        <b-alert
          v-if="selectedQtySerials == order.quantity_products"
          variant="success"
          show
        >
          <div class="alert-body">
            Cantidad de seriales seleccionados: {{ selectedQtySerials }} / {{ order.quantity_products }} solicitados
          </div>
        </b-alert>
        
        <b-alert
          v-else
          variant="danger"
          show
        >
          <div class="alert-body">
            La cantidad seleccionada no es igual a la cantidad solicitada en el pedido <br>
            Cantidad de seriales seleccionados: {{ selectedQtySerials }} / {{ order.quantity_products }} solicitados
          </div>
        </b-alert>

      </b-col>

      <b-col v-if="order && (selectedQtySerials == order.quantity_products)" cols="12" >
        <!-- Action Buttons -->
        <div v-if="order.order_status_id == 2">
          <b-spinner
            v-show="loading"
            variant="primary"
            label="Loading..."
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            />

          <b-button
            v-show="!loading"
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="assignModal = true"
          >
            Asignar seriales
          </b-button>
        </div>

      </b-col>

      <b-col v-if="order" cols="12" >
        
        <div v-if="order.order_status_id == 5">

          <h6 class="text-warning">Estado: {{ order.order_status.name }}</h6>
          <h6>Descripción: {{ order.order_status.description }}</h6>
          <h6>Cantidad de seriales: {{ order.quantity_products }}</h6>

          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="$router.push({name: 'order-list'})"
            class="mt-2"
          >
            Ver pedidos
          </b-button>

        </div>

      </b-col>
      
    </b-row>

    <!-- <b-row>
      <b-col cols="12">

        <b-tabs
          v-if="orderId"
          pills
        >

          <b-tab active>
            <template #title>
              <feather-icon
                icon="BoxIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Alistamiento</span>
            </template>
            <read-serial 
              :order-id="orderId"
              class="mt-2 pt-75"
            />
          </b-tab>

          <b-tab>
            <template #title>
              <feather-icon
                icon="AlertTriangleIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Reportar novedad</span>
            </template>
            <newness-report
              :order-id="orderId"
              class="mt-2 pt-75"
            />
          </b-tab>
          
        </b-tabs>

        
      </b-col>
    </b-row> -->

    <!-- <b-button
      class="float-right"
      variant="outline-secondary"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="$router.push({name: 'order-list'})"
    >
      Cancelar
    </b-button> -->

    <b-modal
      v-model="assignModal"
      title="Atención"
      ok-title="Asignar"
      cancel-title="Cancelar"
      @ok="assignSerialsByBoxes"
    >
    <!-- @ok="assignSerials" -->
      <b-card-text>
        <h5>¿Estás seguro de asignar los seriales al pedido?</h5>
        Al hacer click en asignar automaticamente se asignaran los seriales al pedido para realizar el despacho
      </b-card-text>
    </b-modal>

  </b-card>
  
</template>

<script>
import router from '@/router'
import { 
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BCardText,
  BBadge,
  BMediaAside,
  BMediaBody,
  BCardBody,
  BAlert,
  BSpinner,
 } from 'bootstrap-vue'
import ReadSerial from './ReadSerial.vue'
import NewnessReport from './NewnessReport.vue'
import {constants} from "@/helpers/constants"
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import {formatDate} from "@/helpers/utils"

export default {
  components: {
    
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BCardText,
    BBadge,
    BMediaAside,
    BMediaBody,
    BCardBody,
    BAlert,
    BSpinner,

    NewnessReport,
    ReadSerial
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken'),
      orderId: null,
      order: null,
      stowages: null,
      assignModal: false,
      selectedBoxes: [],
      stowageValue: [],
      selectedQtySerials: 0,
      loading: false,
    }
  },
  created() {
    this.orderId = router.currentRoute.params.order_id
    console.log(this.orderId)
    this.getStowages()
    this.getOrder()
  },
  computed: {
    formatDateVariant() {
      return date => formatDate(date)
    },
  },
  watch: {
    selectedBoxes(newValue, beforeValue) {
      this.selectedQtySerials = 0
      console.log('La variable ha cambiado de', beforeValue, 'a', newValue)

      this.stowages.forEach(element => {

        element.stowage.boxes.forEach(box => {
          if(box.available == 1) {
            if(this.selectedBoxes.includes(box.id)) {
              this.selectedQtySerials = this.selectedQtySerials + box.products_by_box
            }
          }

        })

      })


    },
  },
  methods: {
    getStowages() {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.get(this.host + '/api/front-web/get-stowage-for-enlistment/' + this.orderId, { headers }).then(response => {
        console.log('get-stowage-for-enlistment: ', response)

        this.stowages = response.data.data.stowages
        
      }).catch( err => {
        console.log(err)
      })
    },
    getOrder() {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.get(this.host + '/api/front-web/get-order-enlistment/' + this.orderId, { headers }).then(response => {
        console.log('get-order-enlistment: ', response)

        this.order = response.data
        
      }).catch( err => {
        console.log(err)
      })
    },
    assignSerials() {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.post(this.host + '/api/front-web/assign-serials', {
        'order_id': this.orderId
      }, { headers }).then(response => {
        console.log('assign-serials: ', response)
        this.getOrder()
      }).catch( err => {
        console.log(err)
      })
    },
    assignSerialsByBoxes() {
      this.loading = true
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.post(this.host + '/api/front-web/assign-serials-by-boxes', {
        'order_id': this.orderId,
        'boxes': this.selectedBoxes
      }, { headers }).then(response => {
        console.log('assign-serials-by-boxes: ', response)

        this.loading = false
        this.selectedBoxes = []
        this.getStowages()
        this.getOrder()
      }).catch( err => {
        console.log(err)

        this.loading = false
      })
    },
    selectAll(stowageId) {
      console.log(stowageId)

      this.stowages.forEach(element => {

        if(element.stowage.id == stowageId) {
          element.stowage.boxes.forEach(box => {
            console.log('Stowage id: ', element.stowage.id, box.id, this.stowageValue)

            if(box.available == 1) {
              if(this.stowageValue.includes(element.stowage.id)) {
                this.selectedBoxes.push(box.id)
              } else {
                this.selectedBoxes = this.selectedBoxes.filter(item => item !== box.id)
              }
            }

          })
          
        }
        
      });
    },
    testBoxes() {
      console.log('Selected boxes: ', this.selectedBoxes)
    }
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>